import React, { Fragment } from "react"
import Link from "next/link"

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuIndicator,
  NavigationMenuViewport,
} from "@common/navigationMenu"
import { isString } from "@common/lib/util"
import Svg from "@common/svg"
import Logo from "@common/logo"
import LinkButton, { LinkButtonProps } from "@common/buttons/linkButton"
import Modal from "@common/modal/modal"

export type SubMenuItem = {
  header: string
  description?: string
  url: string
  icon?: React.ReactNode | string
  isExternal?: boolean
}

export type MenuSection = {
  header: string
  items: SubMenuItem[]
}

export type MenuItem = {
  header: string
  url?: string
  columns?: MenuSection[]
  variant?: LinkButtonProps["variant"]
  icon?: React.ReactNode | string
}

const MenuIcon = ({
  icon,
  description,
}: {
  icon: MenuItem["icon"]
  description?: string
}) => {
  if (description)
    return (
      <div
        className="flex items-center justify-center size-10 rounded-md my-auto bg-muted backdrop-blur-sm"
        aria-hidden="true"
      >
        {isString(icon) ? <Svg name={icon} /> : icon}
      </div>
    )
  return <>{isString(icon) ? <Svg name={icon} /> : icon}</>
}

// Main navigation menu component
export function NavigationHeader({
  navMenu = [],
  isDarkMode,
  logo,
  actions = [],
}: {
  navMenu: MenuItem[]
  isDarkMode?: boolean
  logo?: Record<string, string>
  actions: MenuItem[]
}) {
  return (
    <div className="flex items-center py-4">
      <Logo logoObj={logo} isDarkMode={isDarkMode} url="/" />

      <NavigationMenu className="hidden md:flex justify-center">
        <NavigationMenuList>
          {navMenu.map((item, index) => (
            <NavigationMenuItem key={index}>
              {item.columns ? (
                <>
                  <NavigationMenuTrigger className="group bg-transparent hover:bg-transparent hover:text-highlight focus:bg-transparent data-[state=open]:bg-transparent mx-4 inline-flex gap-1 items-center font-semibold text-base">
                    {item.header}{" "}
                    <Svg
                      name="arrow-down-short"
                      classes="relative top-px transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180 size-3"
                    />
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="justify-center">
                    <div className="flex gap-4">
                      {item.columns.map((section, sectionIndex) => (
                        <div key={sectionIndex} className="space-y-2">
                          {section.header && (
                            <h4 className="text-xs font-bold uppercase tracking-wider text-muted-foreground mb-2">
                              {section.header}
                            </h4>
                          )}
                          <ul className="space-y-2 min-w-64">
                            {section.items.map((subItem, subItemIndex) => (
                              <li key={subItemIndex}>
                                <NavigationMenuLink asChild>
                                  <Link
                                    href={subItem.url}
                                    className="flex items-start gap-3 rounded-md p-2 hover:bg-muted"
                                    target={
                                      subItem.isExternal ? "_blank" : undefined
                                    }
                                    rel={
                                      subItem.isExternal
                                        ? "noopener noreferrer"
                                        : undefined
                                    }
                                  >
                                    {subItem.icon && (
                                      <MenuIcon
                                        icon={subItem.icon}
                                        description={subItem.description}
                                      />
                                    )}

                                    <div>
                                      <p className="font-medium">{subItem.header}</p>
                                      {subItem.description && (
                                        <p className="text-sm text-muted-foreground">
                                          {subItem.description}
                                        </p>
                                      )}
                                    </div>
                                    {subItem.isExternal && (
                                      <Svg name="external-link" />
                                    )}
                                  </Link>
                                </NavigationMenuLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </NavigationMenuContent>
                </>
              ) : (
                <NavigationMenuLink asChild>
                  <LinkButton href={item.url || "#"} variant="underline">
                    {item.header}
                  </LinkButton>
                </NavigationMenuLink>
              )}
            </NavigationMenuItem>
          ))}
          <NavigationMenuIndicator />
        </NavigationMenuList>
        <NavigationMenuViewport className="justify-center" />
      </NavigationMenu>

      <div className="ml-auto  items-center space-x-4 shrink-0 hidden md:flex">
        {actions.map((item, index) => (
          <LinkButton key={index} href={item.url || "#"} variant={item.variant}>
            {item.header}
            {isString(item.icon) ? (
              <Svg name={item.icon} classes="w-4 h-4" />
            ) : (
              item.icon
            )}
          </LinkButton>
        ))}
      </div>

      <div className="ml-auto items-center space-x-4 shrink-0 flex md:hidden">
        <Modal>
          <Modal.Trigger>
            <Svg name="menu" classes="w-6 h-6" />
          </Modal.Trigger>
          <Modal.Layout heightClasses="h-[96vh]" widthClasses="w-full">
            <Modal.Header>
              <Logo logoObj={logo} isDarkMode={isDarkMode} url="/" />
            </Modal.Header>

            <Modal.Content>
              <NavigationMenu orientation="vertical" asChild>
                <NavigationMenuList className="w-full *:border-b *:py-4 *:w-full">
                  {navMenu.map((item, index) => (
                    <Fragment key={index}>
                      {item.columns ? (
                        <>
                          {item.columns.map((column) => (
                            <>
                              {column.items.map((subItem, index) => (
                                <NavigationMenuItem
                                  key={index}
                                  className="last:border-0 flex items-center"
                                >
                                  {isString(subItem.icon) ? (
                                    <Svg name={subItem.icon} classes="size-5" />
                                  ) : (
                                    <span className="size-4">{subItem.icon}</span>
                                  )}
                                  <LinkButton
                                    variant="underline"
                                    href={subItem.url}
                                    target={
                                      subItem.isExternal ? "_blank" : undefined
                                    }
                                  >
                                    {subItem.header}
                                  </LinkButton>
                                </NavigationMenuItem>
                              ))}
                            </>
                          ))}
                        </>
                      ) : (
                        <NavigationMenuItem
                          key={index}
                          className="last:border-0 flex items-center"
                        >
                          {isString(item.icon) ? (
                            <Svg name="document" classes="size-5" />
                          ) : (
                            <span className="size-4">{item.icon}</span>
                          )}
                          <LinkButton href={item.url} variant="underline">
                            {item.header}
                          </LinkButton>
                        </NavigationMenuItem>
                      )}
                    </Fragment>
                  ))}
                </NavigationMenuList>
              </NavigationMenu>
            </Modal.Content>

            <Modal.Footer>
              {actions.map((item, index) => (
                <LinkButton
                  key={index}
                  href={item.url || "#"}
                  variant={item.variant}
                  width="full"
                >
                  {item.header}
                  {isString(item.icon) ? (
                    <Svg name={item.icon} classes="w-4 h-4" />
                  ) : (
                    item.icon
                  )}
                </LinkButton>
              ))}
            </Modal.Footer>
          </Modal.Layout>
        </Modal>
      </div>
      {/* Mobile Menu Modal */}
    </div>
  )
}
