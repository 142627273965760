import { cva } from "class-variance-authority"

const alignment = {
  left: "text-left justify-start",
  center: "text-center justify-center",
  right: "text-right justify-end",
}

//☢️☢️☢️☢️ header passes prop to its children in exposed API
const shortHeaderVariants = cva(
  //shortHeaderVariants has color and text-transform variant exceptions due to its use cases
  "leading-4 font-semibold tracking-wider flex",
  {
    variants: {
      variant: {
        h7: "text-xxs-r",
        h6: "text-xxs-r",
        h5: "text-xs-r",
        h4: "text-sm-r",
        h3: "text-base-r",
        h2: "text-base-r",
        h1: "text-lg-r",
      },

      color: {
        primary: "text-foreground",
        secondary: "text-muted-foreground",
        highlight: "text-blue-600",
      },
      transform: {
        uppercase: "uppercase",
        normalcase: "normal-case",
      },
      width: {
        full: "w-full",
        fit: "",
      },
      alignment,
    },
    defaultVariants: {
      variant: "h7",
      alignment: "left",
      color: "highlight",
      transform: "uppercase",
      width: "full",
    },
  }
)
const mainHeaderVariants = cva(
  " w-full font-semibold flex text-primary tracking-tight ",
  {
    variants: {
      variant: {
        h7: "text-lg-r",
        h6: "text-xl-r",
        h5: "h5-r",
        h4: "h4-r",
        h3: "h3-r",
        h2: "h2-r",
        h1: "h1-r",
      },
      alignment,
    },
    defaultVariants: {
      variant: "h7",
      alignment: "left",
    },
  }
)

const descriptionVariants = cva("w-full font-normal flex", {
  variants: {
    variant: {
      h7: "text-sm-r",
      h6: "text-sm-r",
      h5: "text-base-r",
      h4: "text-base-r",
      h3: "text-lg-r",
      h2: "text-xl-r",
      h1: "text-xl-r",
    },
    color: {
      secondary: "text-muted-foreground",
      primary: "text-primary",
      highlight: "text-highlight",
    },
    alignment,
  },
  defaultVariants: {
    variant: "h7",
    alignment: "left",
    color: "secondary",
  },
})

export { descriptionVariants, mainHeaderVariants, shortHeaderVariants }
