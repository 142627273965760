import { joinClassNames } from "@common/lib/util"
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu"
import * as React from "react"

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={joinClassNames("relative z-10 flex w-full", className)}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Root>
))

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={joinClassNames(
      "group flex flex-1 list-none data-[orientation=horizontal]:items-center data-[orientation=vertical]:flex-col",
      className
    )}
    {...props}
  />
))

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={joinClassNames(
      "data-[active]:underline data-[state=open]:underline",
      className
    )}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Trigger>
))

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={joinClassNames(
      "absolute top-0 left-0 w-full sm:w-auto data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft p-4",
      className
    )}
    {...props}
  />
))

const NavigationMenuLink = NavigationMenuPrimitive.Link

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={joinClassNames(
        "perspective-[2000px] absolute left-0 top-full flex w-full",
        className
      )}
    >
      <NavigationMenuPrimitive.Viewport
        ref={ref}
        {...props}
        className="relative mt-2.5 h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-md bg-background border-2 shadow-md border-muted transition-[width,_height] duration-300 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn sm:w-[var(--radix-navigation-menu-viewport-width)]"
      />
    </div>
  )
})

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className="top-full z-10 flex h-2.5 items-end justify-center overflow-hidden transition-[width,transform_250ms_ease] data-[state=hidden]:animate-fadeOut data-[state=visible]:animate-fadeIn"
    {...props}
  >
    <div className="relative top-[70%] size-2.5 rotate-45 rounded-tl-sm bg-muted" />
  </NavigationMenuPrimitive.Indicator>
))

const NavigationMenuItem = NavigationMenuPrimitive.Item
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName

export {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
}
