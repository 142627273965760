import React from "react"
import {
  descriptionVariants,
  mainHeaderVariants,
  shortHeaderVariants,
} from "@common/header/interfaces"
import { Slot } from "@radix-ui/react-slot"
import { joinClassNames } from "@common/lib/util"

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  variant?: "h7" | "h6" | "h5" | "h4" | "h3" | "h2" | "h1"
  alignment?: "left" | "center" | "right"
  color?: "primary" | "secondary" | "highlight"
  transform?: "uppercase" | "normalcase"
  width?: "full" | "fit"
  asChild?: boolean
}

interface HeaderComponent
  extends React.ForwardRefExoticComponent<
    HeaderProps & React.RefAttributes<HTMLDivElement>
  > {
  ShortHeader: typeof ShortHeader
  MainHeader: typeof MainHeader
  Description: typeof Description
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  ({ children, alignment, variant, asChild, ...props }, ref) => {
    if (!children) return null
    const Comp = asChild ? Slot : "div"
    return (
      <Comp ref={ref} className="flex flex-col space-y-3" {...props}>
        {React.Children.map(children, (child: React.ReactNode) => {
          if (React.isValidElement<any>(child)) {
            return React.cloneElement(child, {
              alignment,

              variant,
            })
          }
          return child
        })}
      </Comp>
    )
  }
) as HeaderComponent

const ShortHeader = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      children,
      asChild,
      alignment,
      color,
      variant,
      transform,
      width,
      className,
      ...props
    },
    ref
  ) => {
    if (!children) return null
    const Comp = asChild ? Slot : "div"

    return (
      <Comp
        ref={ref}
        className={joinClassNames(
          shortHeaderVariants({
            alignment,
            color,
            variant,
            transform,
            width,
          }),
          className
        )}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)

const MainHeader = React.forwardRef<HTMLDivElement, HeaderProps>(
  ({ children, asChild, alignment, variant, className, ...props }, ref) => {
    if (!children) return null
    const Comp = asChild ? Slot : "div"

    return (
      <Comp
        ref={ref}
        className={joinClassNames(
          mainHeaderVariants({
            alignment,
            variant,
          }),
          className
        )}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)

const Description = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      children,
      variant,
      alignment,
      asChild,
      color,
      className,
      ...props
    }: HeaderProps,
    ref
  ) => {
    if (!children) return <></>
    const Comp = asChild ? Slot : "div"
    return (
      <Comp
        ref={ref}
        {...props}
        className={joinClassNames(
          descriptionVariants({ variant, alignment, color }),
          className
        )}
      >
        {children}
      </Comp>
    )
  }
)

Header.displayName = "Header"
ShortHeader.displayName = "ShortHeader"
MainHeader.displayName = "MainHeader"
Description.displayName = "Description"

Header.ShortHeader = ShortHeader
Header.MainHeader = MainHeader
Header.Description = Description

export { Header }
