import Footer from "@common/footer"
import { ReactNode } from "react"
import { useTheme } from "next-themes"
import Sticky from "@common/sticky"
import Container from "@common/v2/container"
import { NavigationHeader } from "@common/v2/navigation/navigationHeader"
import { NextPage } from "next"

export default function LandingNew({
  children,
  siteConfig = {},
}: {
  children: ReactNode
  siteConfig: Record<string, any>
}) {
  const {
    // headerLinks,
    // mobileLinks,
    // headerCtaLinks,
    // site,
    logo,
    footerSummary,
    email,
    company,
    address,
    footerLinks,
    menu,
  } = siteConfig

  const { theme } = useTheme()
  const isDarkMode = theme == "dark"

  return (
    <div className="bg-background">
      <Sticky stickyClasses="border-b" classes="mb-2">
        <header className="backdrop-blur-md">
          <Container size="8xl">
            <NavigationHeader
              isDarkMode={isDarkMode}
              logo={logo}
              navMenu={menu?.menuItems}
              actions={menu?.actions}
            />
          </Container>
        </header>
      </Sticky>

      <main className="flex-grow flex flex-col">{children}</main>

      {siteConfig.footer?.visible && (
        <Footer
          logoObj={logo}
          footerSummary={footerSummary}
          email={email}
          company={company}
          line1={address && address?.line1}
          line2={address && address?.line2}
          footerLinks={footerLinks}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  )
}

// context - read option 4 (bonus) here https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout = (
  page: NextPage,
  { siteConfig }: { siteConfig: Record<string, any> }
) => <LandingNew siteConfig={siteConfig}>{page as any}</LandingNew>
